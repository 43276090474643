import React from 'react';
import {useTranslation} from 'react-i18next';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import {withLocaleProvider} from '../../locale/LocaleProvider';

export const WorkWithUsPage = () => {
  const {t, i18n} = useTranslation();
  return (
    <>
      <Seo
        lang={i18n.language}
        title={t('Work-with-us:seo:title')}
        description={t('Work-with-us:seo:description')}
        url={t('Work-with-us:seo:url')}
      />
      <Layout>
        <div className="breadcrumbs-v3 img-v5">
          <div className="container text-center">
            <p>{t('Work-with-us:intro:subtitle')}</p>
            <h1>{t('Work-with-us:intro:title')}</h1>
          </div>
        </div>
        <div className="container content-sm">
          <div className="headline-left margin-bottom-30">
            <h2 className="headline-brd">{t('Work-with-us:contents:title')}</h2>
          </div>
          <ul>
            <li>{t('Work-with-us:contents:item-1')}</li>
            <li>{t('Work-with-us:contents:item-2')}</li>
            <li>{t('Work-with-us:contents:item-3')}</li>
            <li>{t('Work-with-us:contents:item-4')}</li>
            <li>{t('Work-with-us:contents:item-5')}</li>
            <li>{t('Work-with-us:contents:item-6')}</li>
            <li>{t('Work-with-us:contents:item-7')}</li>
            <li>{t('Work-with-us:contents:item-8')}</li>
          </ul>

          <h3
            dangerouslySetInnerHTML={{
              __html: t('Work-with-us:contents:candidacy'),
            }}
          />
        </div>
      </Layout>
    </>
  );
};

export default withLocaleProvider('it', WorkWithUsPage);
